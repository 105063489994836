import logo from './assets/logo.png';
import './App.css';

function App() {
  return (
    <div className="container mx-auto">
      <header className="border-b border-b-black">
        <img src={logo} className="w-24 mx-auto" alt="Logo Navuar" />
      </header>
      <section className='grid grid-cols-1 md:grid-cols-3 grid-rows-3 gap-4 py-4 mt-6 bg-image min-h-screen'>
        <div className='md:col-end-4 text-center md:text-left'>
          <h1 className='text-6xl py-5'>Navuar</h1>
          <p className='text-3xl leading-relaxed'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin posuere dui eget felis aliquam, eget dignissim lorem tincidunt.</p>
        </div>
        <div className=''>
          <h3 className='text-6xl py-5 leading-tight md:text-right  text-center'>
            Elegante
            <span className='block'>Excepcional</span>
          </h3>
        </div>
        <div className='md:row-start-3 md:col-end-4 justify-center text-center'>
          <div className='p-6 rounded-full leading-relaxed border border-black inline-block w-56 h-56'>
            <h2 className='text-3xl align-middle mt-7'>Colección</h2>
            <p className='text-4xl align-middle leading-relaxed'>4 Elementos</p>
            <span className='text-2xl align-middle'>pronto</span>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
